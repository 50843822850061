import React, {
  AriaAttributes,
  FC,
  FocusEventHandler,
  MouseEventHandler,
  TouchEventHandler,
} from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'

import * as SC from './styled'

export type LinkUnion = HTMLButtonElement | HTMLLinkElement | HTMLAnchorElement

type InternalLinkProps = {
  className?: string
  target?: string
  title?: string
  role?: string
  tabIndex?: number
  rel?: string
  nextAs?: string
  href?: string
  onClick?: MouseEventHandler<LinkUnion>
  onFocus?: FocusEventHandler<LinkUnion>
  onMouseEnter?: MouseEventHandler<LinkUnion>
  onTouchStart?: TouchEventHandler<LinkUnion>
  label?: string
  text?: string
  ariaLabel?: string
  disabled?: boolean
  children?: React.ReactNode
  type?: React.ButtonHTMLAttributes<any>['type']
  itemProp?: string
  isNativeLink?: boolean
  data?: {
    [key: string]: any
  }
}

export type LinkProps = AriaAttributes &
  Omit<NextLinkProps, 'as' | 'href' | 'className' | keyof InternalLinkProps> &
  InternalLinkProps

export type TextLinkProps = (LinkProps & { text: string }) | null

const Link: FC<LinkProps> = (props) => {
  const {
    className,
    children,
    href,
    // prefetch,
    replace,
    scroll,
    shallow,
    locale,
    target,
    title,
    label,
    role,
    tabIndex,
    rel,
    onClick,
    onFocus,
    ariaLabel,
    nextAs,
    disabled,
    type,
    text,
    data,
    isNativeLink,
    ...extraProps
  } = props

  const hasRouter = !!useRouter()

  return (
    <>
      {href && hasRouter && !isNativeLink && (
        <NextLink
          href={href}
          passHref
          prefetch={false}
          {...(replace && { replace })}
          {...(scroll !== undefined && { scroll })}
          {...(shallow && { shallow })}
          {...(locale && { locale })}
          {...(target && { target })}
          {...(tabIndex && { tabIndex })}
          {...(onFocus && { onFocus })}
          {...(nextAs && { as: nextAs })}
          {...(data &&
            Object.entries(data).reduce(
              (entries, [key, value]) => ({
                ...entries,
                ['data-' + key]: value,
              }),
              {}
            ))}
          legacyBehavior
        >
          <SC.Link
            $disabled={disabled}
            className={`${className} is-link`}
            {...(title && { title })}
            {...(onClick && { onClick })}
            {...(rel && { rel })}
            {...(role && { role })}
            {...(target && { target })}
            {...(data &&
              Object.entries(data).reduce(
                (entries, [key, value]) => ({
                  ...entries,
                  ['data-' + key]: value,
                }),
                {}
              ))}
            {...extraProps}
          >
            {children || label || text}
          </SC.Link>
        </NextLink>
      )}
      {href && (!hasRouter || isNativeLink) && (
        <SC.Link
          $disabled={disabled}
          href={href}
          title={title}
          className={`${className} is-link`}
          {...(onFocus && { onFocus })}
          {...(data &&
            Object.entries(data).reduce(
              (entries, [key, value]) => ({
                ...entries,
                ['data-' + key]: value,
              }),
              {}
            ))}
          {...extraProps}
        >
          {children || label || text}
        </SC.Link>
      )}
      {!href && (onClick || !!type) && (
        <SC.Button
          $disabled={disabled}
          className={`${className} is-button`}
          onClick={!disabled ? onClick : undefined}
          type={type}
          {...(onFocus && { onFocus })}
          {...(ariaLabel && { 'aria-label': ariaLabel })}
          {...(data &&
            Object.entries(data).reduce(
              (entries, [key, value]) => ({
                ...entries,
                ['data-' + key]: value,
              }),
              {}
            ))}
          {...extraProps}
          {...(role && { role })}
        >
          {children || label || text}
        </SC.Button>
      )}
      {!href && !(onClick || !!type) && (
        <SC.Span
          $disabled={disabled}
          className={`${className} is-span`}
          {...(onFocus && { onFocus, tabIndex: 0 })}
          {...(data &&
            Object.entries(data).reduce(
              (entries, [key, value]) => ({
                ...entries,
                ['data-' + key]: value,
              }),
              {}
            ))}
          {...extraProps}
          {...(role && { role })}
        >
          {children || label || text}
        </SC.Span>
      )}
    </>
  )
}

export default Link
